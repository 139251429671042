import React from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';

function Example() {
  return (
    <Grid container spacing={3}>
      {/* Card Example */}
      <Grid item xs={12} md={6}>
        <Card >
          <CardContent>
            <Typography variant="h5" color="text.primary">
              Welcome to the Custom Theme
            </Typography>
            <Typography variant="body1" color="text.secondary">
              This is an example of a card using the custom Material-UI theme.
            </Typography>
          </CardContent>
          <Button variant="outlined" color="primary" sx={{ marginRight: '10px' }}>
          Primary Button
        </Button>
        <Button variant="contained" color="secondary">
          Secondary Button
        </Button>
        </Card>
        
      </Grid>

      {/* Buttons Example */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6" color="text.primary" gutterBottom>
          Buttons
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginRight: '10px' }}>
          Primary Button
        </Button>
        <Button variant="contained" color="secondary">
          Secondary Button
        </Button>
      </Grid>

      {/* Typography Example */}
      <Grid item xs={12}>
        <Typography variant="h4" color="text.primary">
          Typography
        </Typography>
        <Typography variant="body1" color="text.secondary">
          This is an example of text styled with the custom theme. You can use the text color and variants defined in the theme.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Example;
