import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0A4D68',
            light: '#088395',
            dark: '#052A40',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#088395',
            light: '#05BFDB',
            dark: '#066A74',
            contrastText: '#FFFFFF',
        },
        background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#0A4D68',
            secondary: '#088395',
        },
    },
    typography: {
        fontFamily: "'Segoe UI', sans-serif",
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '16px',
                    marginBottom: '5px',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    marginBottom: '20px',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px'
                },
                input: {
                    padding: '8px 16px',
                    height: 'unset',
                    lineHeight: 1.6,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '20px',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: 'none',
                    fontSize: '16px'
                },
                containedPrimary: {
                    backgroundColor: '#0A4D68',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#0b9fb5',
                        boxShadow: 'none'
                    },
                },
                containedSecondary: {
                    backgroundColor: '#088395',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#05BFDB',
                        boxShadow: 'none'
                    },
                },
                outlinedPrimary: {
                    borderColor: '#0A4D68',
                    color: '#0A4D68',
                    '&:hover': {
                        borderColor: '#088395',
                        backgroundColor: '#0A4D68',
                        color: '#FFFFFF',
                        boxShadow: 'none'
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E0E0E0',
                    boxShadow: 'none',
                    padding: '16px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    color: '#0A4D68',
                },
                h2: {
                    color: '#088395',
                },
                body1: {
                    color: '#052A40',
                },
            },
        },
    },
});

export default theme;
