import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Example from './pages/Example';
import Register from './pages/Register';
import theme from './theme/theme';
import Login from './pages/Login';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <CssBaseline />
                <Navbar />
                <Routes>
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    {/* for demo */}
                    <Route path="/example" element={<Example />} /> 
                    {/* <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} /> */}
                </Routes>

            </Router>
        </ThemeProvider >
    );
}

export default App;
