import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const navLinks = [
    {
        name: 'Đăng nhập',
        url: '/login'
    }
];

const navLinksLeft = [
    {
        name: 'Trang chủ',
        url: '/'
    },
    {
        name: 'Về chúng tôi',
        url: '/'
    },
    {
        name: 'Liên hệ',
        url: '/'
    },
]
const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 3 }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" component="div" marginInlineEnd={'36px'}>
                        Lê Thảo Gateway
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1  }} >
                        {navLinksLeft.map((link) => (
                            <Button
                                component={Link}
                                key={link.name}
                                color="inherit"
                                sx={{
                                    textTransform: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                                to={link.url}
                            >
                                <Typography variant='overlines' color='white'>{link.name}</Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navLinks.map((link) => (
                            <Button
                                component={Link}
                                key={link.name}
                                color="inherit"
                                sx={{
                                    textTransform: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                                to={link.url}
                            >
                                <Typography variant='overlines' color='white'>{link.name}</Typography>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List>
                        {navLinksLeft.map((text) => (
                            <ListItem key={text.name} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={text.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        {navLinks.map((text) => (
                            <ListItem key={text.name} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={text.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Navbar;
