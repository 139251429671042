import { LoadingButton } from '@mui/lab';
import { Card, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

function Register() {
    const [loading, setLoading] = useState(false);

    const [appSelect, setAppSelect] = useState('')

    const handleClickButton = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    const handleAppSelect = (e) => {
        e.preventDefault();
        setAppSelect(e.target.value)
    }
    return (
        <Container fixed>
            <Card sx={{
                marginTop: '100px',
                marginBottom: '100px',
            }}>
                <Typography variant="h5" color="text.primary" fontWeight={'700'} sx={{ marginBottom: '24px' }}>
                    Đăng ký tài khoản CTV
                </Typography>
                <form>
                    <InputLabel id="name-label">Họ & Tên bạn</InputLabel>
                    <TextField
                        id="name-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Họ & Tên bạn'
                        autoComplete='name'
                    />
                    <InputLabel id="email-label">Địa chỉ email</InputLabel>
                    <TextField
                        id="email-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        type='email'
                        placeholder='Địa chỉ email'
                        autoComplete='email'
                    />
                    <InputLabel id="username-label">Username</InputLabel>
                    <TextField
                        id="username-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Username'
                        autoComplete='username'
                    />
                    <InputLabel id="phone-label">Số điện thoại</InputLabel>
                    <TextField
                        id="phone-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Số điện thoại'
                        autoComplete='tel'
                    />
                    <InputLabel id="password-label">Mật khẩu của bạn</InputLabel>
                    <TextField
                        id="password-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        type='password'
                        placeholder='Mật khẩu của bạn'
                        autoComplete='new-password'
                    />
                    <InputLabel id="re-password-label">Nhập lại mật khẩu</InputLabel>
                    <TextField
                        id="re-password-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            }
                        }}
                        type='password'
                        placeholder='Nhập lại mật khẩu'
                        autoComplete='new-password'
                    />
                    <InputLabel id="app-select-label">Chọn app muốn làm CTV</InputLabel>
                    <Select
                        id="app-select"
                        fullWidth
                        value={appSelect}
                        displayEmpty
                        inputProps={{
                            'aria-label': 'Without label'
                        }}
                        onChange={handleAppSelect}
                    >
                        <MenuItem value={'pati'}>Pati</MenuItem>
                        <MenuItem value={'mico'}>Mico</MenuItem>
                    </Select>
                    <InputLabel id="partner-type-label">Bạn muốn đăng ký hình thức CTV nào?</InputLabel>
                    <FormControl sx={{ marginBottom: '10px' }}>
                        <RadioGroup
                            id="partner-type-radio"
                            name="partner-type-radio"
                        // value={value}
                        // onChange={handleChange}
                        >
                            <FormControlLabel value="female" control={<Radio />} label="CTV chính thức" />
                            <FormControlLabel value="male" control={<Radio />} label="CTV tạm ứng" />
                        </RadioGroup>
                    </FormControl>
                    <InputLabel id="idCard-label">CCCD của bạn</InputLabel>
                    <TextField
                        id="idCard-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='CCCD của bạn'
                    />
                    <Typography
                        variant="inherit"
                        component="h2"
                        fontWeight="600"
                        color='secondary'
                        textAlign="center"
                        fontStyle="italic"
                    >
                        Vui lòng liên hệ qua Zalo dưới đây để gửi ảnh CCCD của bạn <br></br>
                        SĐT:
                    </Typography>
                    <InputLabel id="bank-label">Tên ngân hàng</InputLabel>
                    <TextField
                        id="bank-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Tên ngân hàng'
                    />
                    <InputLabel id="bankNum-label">Số tài khoản</InputLabel>
                    <TextField
                        id="bankNum-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Số tài khoản'
                    />
                    <LoadingButton loading={loading} type='submit' onClick={handleClickButton} variant='contained' fullWidth>Đăng ký</LoadingButton>
                </form>
            </Card>
        </Container>
    );
}

export default Register;
