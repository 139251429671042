import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const Login = () => {
    const [loading, setLoading] = useState(false);

    const handleClickButton = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }
    return (
        <Container fixed>
            <Card sx={{
                marginTop: '100px',
                marginBottom: '100px',
                textAlign: 'center',
                alignItems: 'center'
            }}>
                <Box
                    component={'img'}
                    sx={{
                        width: { xs: '60%', sm: '35%', md: '30%', lg: '25%' },
                        textAlign: 'center',
                        margin: 'auto',
                        aspectRatio: '1 / 1',
                        marginBottom: '36px',
                        marginTop: '20px',
                    }}
                    src="/logo-placeholder.png" />
                <form>
                    <TextField
                        id="username-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Username/Email/SĐT'
                        autoComplete='username'
                    />
                    <TextField
                        id="password-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        placeholder='Mật khẩu'
                        autoComplete='password'
                    />
                    <LoadingButton loading={loading} type='submit' onClick={handleClickButton} variant='contained' fullWidth>Đăng nhập</LoadingButton>
                </form>
                <Box display={'flex'} alignContent={'center'} marginTop={'10px'} justifyContent={'center'}>
                <Button
                    component={Link}
                    sx={{
                        textTransform: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                            backgroundColor:'unset'
                        },
                    }}
                    to={'/register'}
                >
                    <Typography variant='overlines'>Đăng ký</Typography>
                </Button>
                <Box sx={{
                    paddingTop:'8px',
                }}>
                <Typography variant='overlines' fontWeight={600}>|</Typography>
                </Box>
                <Button
                    component={Link}
                    sx={{
                        textTransform: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                            backgroundColor:'unset'
                        },
                    }}
                    to={'/'}
                >
                    <Typography variant='overlines'>Quên mật khẩu?</Typography>
                </Button>
                </Box>
            </Card>
        </Container>
    )
}

export default Login;